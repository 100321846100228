<template>
  <div class="inner-section">
    <card>
      <!-- formData section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('priceMonitoring.fair_price') }} {{ $t('globalTrans.details') }}</h4>
      </template>
      <template v-slot:searchHeaderAction>
        <router-link class="btn-add" to="/bazar-monitoring-service/commodity-price-market-monitoring/fair-price"><i class="ri-arrow-left-line"></i> {{ $t('priceMonitoring.fair_price') }} {{ $t('globalTrans.list') }}</router-link>
      </template>
      <template v-slot:searchBody>
        <b-overlay :show="loadData">
          <b-row>
            <b-table-simple hover small responsive bordered>
              <b-tr>
                <b-th style="vertical-align:middle">{{ $t('priceMonitoring.fair_price_created_date') }}</b-th>
                <b-th style="vertical-align:middle">{{ formData.fair_price_created_date | dateFormat }}</b-th>
                <b-th style="vertical-align:middle">{{ $t('priceMonitoring.approver_name') }}</b-th>
                <b-th style="vertical-align:middle">{{ ($i18n.locale==='bn') ? formData.approver_name_bn : formData.approver_name }}</b-th>
              </b-tr>
            </b-table-simple>
          </b-row>
          <b-row>
            <b-table-simple hover small responsive bordered>
              <b-tr style="background: #f3f3f3">
                <b-th style="width: 5%; vertical-align:middle">{{ $t('globalTrans.sl_no') }}</b-th>
                <b-th style="width: 5%; vertical-align:middle">{{ $t('bazarMonitoring.commodity_group') }}</b-th>
                <b-th style="width: 5%; vertical-align:middle">{{ $t('bazarMonitoring.commodity_name') }}</b-th>
                <b-th style="width: 5%; vertical-align:middle">{{ $t('bazarMonitoring.measurement_unit') }}</b-th>
                <b-th style="width: 5%; vertical-align:middle" class="text-center">{{ $t('priceMonitoring.retail_fair_price') }}</b-th>
                <b-th style="width: 5%; vertical-align:middle" class="text-center">{{ $t('priceMonitoring.whole_sale_fair_price') }}</b-th>
              </b-tr>
              <template v-for="(item, index) in formData.price_details">
                <b-tr :key="index ">
                  <b-td style="vertical-align:middle">{{ $n(index+1) }}</b-td>
                  <b-td style="vertical-align:middle">
                    {{ getColumnName($store.state.BazarMonitoringService.commonObj.commodityGroupList,'value', item.commodity_group_id) }}
                  </b-td>
                  <b-td style="vertical-align:middle">
                    {{ getColumnName($store.state.BazarMonitoringService.commonObj.commodityNameList,'value', item.commodity_name_id) }}
                  </b-td>
                  <b-td style="vertical-align:middle">
                    {{ getColumnName($store.state.BazarMonitoringService.commonObj.unitList,'commodity_name_id', item.commodity_name_id) }}
                  </b-td>
                  <b-td style="vertical-align:middle">
                    {{ $n(item.retail_fair_price) }}
                  </b-td>
                  <b-td style="vertical-align:middle">
                    {{ $n(item.whole_sale_fair_price) }}
                  </b-td>
                </b-tr>
              </template>
            </b-table-simple>
          </b-row>
        </b-overlay>
      </template>
    </card>
  </div>
</template>
<style>
.btn-success {
  background-color: var(--success) !important;
  border-color: var(--success);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.form-control {
  background-color: #f2f2f2 !important;
}
.price-entry .form-control {
  margin-bottom: 0px !important;
}
</style>
<script>
import RestApi, { bazarMonitoringServiceBaseUrl } from '@/config/api_config'
import { fairPriceShow } from '../../api/routes'

import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    // AreaType
  },
  data () {
    return {
      saveBtnName: this.$route.params.id ? this.$t('globalTrans.update') : this.$t('globalTrans.submit'),
      formData: {
        id: 0,
        fair_price_created_date: null,
        price_details: []
      },
      editItemId: '',
      loadData: false,
      sortDesc: true,
      commodityName: false
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.loadApi()
    }
  },
  methods: {
    async loadApi () {
     this.loadData = true
      const result = await RestApi.getData(bazarMonitoringServiceBaseUrl, fairPriceShow + '/' + this.$route.params.id)
      if (result.success) {
        this.formData = result.data
        this.loadData = false
      } else {
        this.loadData = false
      }
    },
    getColumnName (list, field, groupId) {
      const obj = list.find(item => item[field] === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        } else {
          return obj.text_en
        }
      } else {
        return ''
      }
    }
  }
}
</script>
